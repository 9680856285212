import Coffee from './components/Coffee/Coffee';
import { Header } from './components/Header';
import './styles/style.scss';

import { Outlet } from '@tanstack/router';

function App() {
  return (
    <div id="container" className="container">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;

export const Footer = () => {
  return (
    <footer className="es-footer">
      <div className="es-footer__wrapper fw">
        <div className="es-footer__description">
          <p>
            Whether you&apos;re new to Baldur&apos;s Gate or a veteran who&apos;s stormed the Sword Coast a hundred times, BG
            AutoChar offers a fresh spin. Create a single character or a full party with one click. Reroll endlessly
            until something sparks your imagination. Tired of the same old Kensai/Mage or Berserker combo? Shake things
            up and start your next adventure with a crew you&apos;d never expect.
          </p>
          <p style={{ marginTop: '1rem' }}>© {new Date().getFullYear()} BG AutoChar</p>

        </div>
        <div className="flex-col flex-center flex-1 f-a-c">
          <label className="es-label">Contact</label>
          <a className="es-a" href="mailto:info@bgautochar.com">
            Email
          </a>
          <Coffee />
          <div className='es-footer__description' style={{ marginTop: '1rem', maxWidth: "50cqw" }}>
            <p>
            💛 This site is a passion project — built for those of us who grew up loving the game and just want to bring a little fun back into it. I don&apos;t run ads, just doing this because I enjoy it.
If you're enjoying it too and feel like tossing a coffee my way, I&apos;d be incredibly grateful.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
