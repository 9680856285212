export const Header = () => {
  return (
    <header id="characterGeneratorHeader" className="header">
      <img
        src={'./images/Character Generator.png'}
        className=" bg-header__img"
        alt="BG AutoChar logo - Baldur's Gate character generator"
      />
    </header>
  );
};
